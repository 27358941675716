var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","loading":_vm.loading,"no-data-text":_vm.noPaymentContactParticipant ? 'No payment contact participant. Create one.' : 'No Bank Accounts on File'},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[(_vm.showTitle)?_c('v-toolbar-title',[_vm._v("Bank Accounts")]):_vm._e(),_c('v-spacer'),(
              (_vm.participant &&
                _vm.selectedParticipant &&
                (_vm.selectedParticipant.id == _vm.participant.id || _vm.$privilege.hasPrivilege('PLAID_ACCOUNT_CREATE'))) ||
                (_vm.organization && _vm.$privilege.hasPrivilege('PLAID_ACCOUNT_CREATE'))
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.addPlaidAccount.apply(null, arguments)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Bank Account ")],1)]}}],null,false,1068466427)},[_c('span',[_vm._v("Add New Bank Account")])]):_vm._e(),(_vm.organization)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'participant', params: { id: 0 } })}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-person")]),_vm._v(" Create Payment Contact Participant ")],1)]}}],null,false,781154527)},[_c('span',[_vm._v("Create Payment Contact Participant")])]):_vm._e()],1)]},proxy:true},{key:"item.primary",fn:function(ref){
            var item = ref.item;
return [(item.primary)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_vm._e()]}},{key:"item.reauthenticationNeeded",fn:function(ref){
            var item = ref.item;
return [(item.reauthenticationNeeded)?_c('span',[_vm._v("Yes")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.plaidAccount)?_c('v-btn',{staticClass:"ma-1",attrs:{"disabled":_vm.loading,"text":"","small":""},on:{"click":function($event){return _vm.updatePlaidAccount(item)}}},[_vm._v("Reauthenticate")]):_vm._e(),(!item.primary)?_c('v-btn',{staticClass:"ma-1",attrs:{"color":"success","disabled":_vm.loading,"text":"","small":""},on:{"click":function($event){return _vm.onMakePrimaryBankAccount(item)}}},[_vm._v("Make Primary")]):_vm._e(),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"red","disabled":_vm.loading,"text":"","small":""},on:{"click":function($event){return _vm.onRemoveBankAccount(item)}}},[_vm._v(_vm._s(_vm.$i18n.translate("Remove")))])]}},{key:"item.provider",fn:function(ref){
            var item = ref.item;
return [(item.plaidAccount)?_c('span',[_vm._v("Plaid")]):(item.vopayIQ11Account)?_c('span',[_vm._v("Vopay")]):_c('span',[_vm._v("N/A")])]}}])}),_c('PlaidDialog',{attrs:{"participant":_vm.finalParticipant,"language":_vm.selectedLocale.languageType,"existingPlaidAccount":_vm.plaidAccountToUpdate},on:{"complete":_vm.completePlaidAccountLink},model:{value:(_vm.plaidDialog),callback:function ($$v) {_vm.plaidDialog=$$v},expression:"plaidDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }