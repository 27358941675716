<template>
  <div>
    <v-text-field
      @input="onInput"
      :value="value && value.id ? value.displayName : ''"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="$listeners"
      :clearable="clearable"
      readonly
    >
      <template v-slot:append-outer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn v-on="on" @click="updateBankAccount" :loading="plaidDialog">
              <span v-if="value && value.id"><v-icon>mdi-circle-edit-outline</v-icon> Update</span>
              <span v-else><v-icon>mdi-plus</v-icon> Add</span>
            </v-btn>
          </template>
          <span v-if="value && value.id">{{ $i18n.translate("Update") }}</span>
          <span v-else>{{ $i18n.translate("Add") }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <PlaidDialog
      v-model="plaidDialog"
      :existingPlaidAccount="value && value.id && value.plaidAccount ? value.plaidAccount : null"
      @complete="completePlaidAccountLink"
      :participant="participant"
      :forExternalAccount="forExternalAccount"
      :isPublic="isPublic"
    />
  </div>
</template>

<script>
import PlaidDialog from "../common/transaction/PlaidDialog";

export default {
  name: "BankAccountField",
  components: { PlaidDialog },
  props: {
    value: Object,
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    participant: Object,
    existingPlaidAccount: Object,
    forExternalAccount: { type: Boolean, default: false },
    isPublic: { type: Boolean, default: false }
  },
  data() {
    return {
      plaidDialog: false,
      items: [],
      selectedAwardVehicle: undefined
    };
  },
  methods: {
    updateBankAccount() {
      this.plaidDialog = true;
    },
    onInput(obj) {
      this.$emit("input", obj);
    },
    completePlaidAccountLink(plaidAccount) {
      this.plaidDialog = false;
      this.$emit("bankAccount", plaidAccount.bankAccount);
    }
  }
};
</script>
